const StationList = ({ userInfo, station, setStation}) => {
    console.log("stations", userInfo);
    return (
            <>
            <div className="ml-3 font-bold">{userInfo.stations.length} Station{userInfo.stations.length !== 1 && "en"}</div>
            <ul className="divide-y divide-gray-200">
            {userInfo?.stations && userInfo.stations.map((s) =>  {
                return (<li key={s.id} className="py-4 flex">
                        <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                        <input type="radio" id={s.id} onClick={() => setStation(s.id)} readOnly checked={s.id === station} />
                        <label htmlFor={s.id}> {s.displayname} <span className="text-gray-500"> ({s.id})</span></label>
                        </p>
                        </div>
                        </li>);})
            }
            </ul>
            </>
    )};

export default StationList;
