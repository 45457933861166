import { Disclosure, Dialog, Menu, Transition } from '@headlessui/react';
import {jwtDecode} from "jwt-decode";

const DebugDialog = ({isDbgOpen, dbgClose, token}) => {
  return(
    <Dialog open={isDbgOpen} onClose={() => dbgClose()} className="relative z-50">
              {/* Backdrop */ }
              <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
              {/* Full-screen scrollable container */}

              <div className="fixed inset-0 w-full">
                {/* Container to center the panel */}
                <div className="flex min-h-full items-center justify-center p-4">
                  <Dialog.Panel className="mx-auto max-w-full rounded bg-white">
                    <Dialog.Title>Debug Information</Dialog.Title>
                    <Dialog.Description>
                      Debug Information
                    </Dialog.Description>

          <pre className="fontMono overflow-auto whitespace-pre-wrap">{JSON.stringify(token && jwtDecode(token))}</pre>

          <button onClick={() => dbgClose()}
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"

                    >Close</button>
                  </Dialog.Panel>
                </div>
                </div>
                </Dialog>
  );
};


export default DebugDialog;
