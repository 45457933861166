import {useState, useEffect, useReducer} from 'react';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import ReactTimeAgo from 'react-time-ago';
import Config from './config.js';
import {BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer} from 'recharts'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const byRevLm = (v1, v2) => {
    let lm1 = v1.lm;
    let lm2 = v2.lm;
    return lm1 < lm2 ? 1 : lm1 > lm2 ? -1 : 0;
}

const frequencies = (arr) => {
    return arr.reduce((acc, curr) => {
        acc[curr] ??= 0;
        acc[curr]++;

        return acc;
    }, {});
}

const justDate = (isoString) => {
    return isoString.substring(0, isoString.indexOf("T"));
}

const Chart = (({list}) => {

    let countByDay = frequencies(list.map(v => justDate(v.lm)))
    let data = Object.entries(countByDay).map(([k,v]) => {return {name: k, v:v }});


    let datax = [{"name": "2024-01-01", "v": 10},
                 {"name": "2024-01-02", "v": 9},
                {"name": "2024-01-03", "v": 12},
                {"name": "2024-01-04", "v": 11},
                {"name": "2024-01-05", "v": 8},
                {"name": "2024-01-06", "v": 5},
                {"name": "2024-01-07", "v": 7},
               ];

    return ( <ResponsiveContainer width="100%" height={250}>
                 <BarChart data={data}>
                     <CartesianGrid strokeDasharray="3 3" />
                     <XAxis dataKey="name" />
                     <YAxis />
                     <Legend />
                     <Bar dataKey="v" fill="#1f2937" name="Videos pro Tag"/>
                 </BarChart>
             </ResponsiveContainer>);
})

const VideoList = ({token, station, stationName}) => {

    const ranges = [{id:"recent", title:"Neueste"},
                    {id:"today", title:"Heute"},
                    {id: "week", title: "Diese Woche"},
                    {id: "lastweek", title: "Letzte Woche"},
                    {id: "month", title: "Laufender Monat"},
                    {id: "lastmonth", title: "Letzter Monat"},
                    {id: "all", title: "Alle"}];

    const [videos, setVideos] = useState({state: "new", list: []});
    const [fu, forceUpdate] = useReducer((x) => x + 1, 0);
    const [selectedTab, setTab] = useState(0);
    const range = ranges[selectedTab].id;

    useEffect(() => {
        const fetchVideos = async () => {
            if(range === null) {
                setVideos({state: "error", msg: "Zeitraum muß gewählt sein."});
                return;
            };
            if(station === null || station === 'null' || station === undefined) {
                setVideos({state: "error", msg: "Station muß gewählt sein."});
                return;
            }

            setVideos(videos => {return {...videos, state: "loading"};});
            console.log("Fetching videos for", station);
            try {
                let response = await fetch(Config.listVideosUrls + "?range=" + range + "&station=" + station,
                                           {headers: {authorization: 'Bearer ' + token },
                                            credentials: "include"
                                           });
                if(response.status === 200) {
                    const r = await response.json();
                    r.videos.reverse() ;
                    setVideos({state: "loaded", videos: r});
                } else {
                    setVideos({state: "error", msg: await response.text(), status: response.status});
                }
            } catch(err) {
                console.log("Error fetching videos", err);
                setVideos({state:"error", msg: err.msg});
            }
        };
        fetchVideos(); } , [token, range, station, fu]);
    console.log("VideoList state", videos.state);


    const b = <button className="h-4 w-4" onClick={forceUpdate}><ArrowPathIcon className="btn-icon"/></button>;

    return (
        <>
            <ul className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700
                        mb-5 flex list-none flex-row flex-wrap" role="tablist" >
                {ranges.map(({id,title}, idx) =>
                    <li role="presentation" key={id}>
                        <button onClick={() => setTab(idx)}
                                className={classNames((selectedTab === idx) ? " border-b-4 border-b-gray-400" : "",
                                                      "mt-2 block border-transparent px-7 pb-3 pt-4 " +
                                                      "text-xs font-medium uppercase leading-tight text-neutral-500 " +
                                                      "hover:isolate hover:border-b-gray-400 focus:isolate")}
                                role="tab" aria-controls="tabs-range" aria-selected={selectedTab === idx}>{title}</button>
                    </li>
                )}
            </ul>

            {(videos.state === "loading") && <div className="ml-3">Lade Videos...</div>}

            {(videos.state === "error") &&
             <div className="ml-3 text-red-300">Fehler beim Laden ({videos.status}): {videos.msg} {b}</div>}

            {(videos.state === "loaded") &&
             <>
                 <div className="ml-3 chart">
                     <Chart list={videos.videos.videos}/>
                 </div>

                 <div className="ml-3 font-bold">{videos.videos.videos.length} Video{videos.videos.videos.length !== 1 && "s"} für Station {stationName} {b}</div>
                 <ul className="divide-y divide-gray-200">
                     {videos.videos.videos.toSorted(byRevLm).map((video) =>  {
                         return (<li key={video.id} className="py-4 flex">
                                     <div className="ml-3">
                                         <p className="text-sm font-medium text-gray-900">
                                             <a style={{minWidth: '7em', display: 'inline-block'}} href={Config.videoPortalUrl + "#" + video.id} target="video-player">{video.id}</a>
                                             <span style={{display: 'inline-block', minWidth: '5em', textAlign: 'right'}} className="text-gray-500">
                                                 {video.duration? (<>{video.duration} s</>) :""}
                                             </span>
                                             <span style={{minWidth: '4em', display: 'inline-block'}} className="pl-4 text-gray-700">
                                                 {video.camera}
                                             </span>
                                             <span style={{minWidth: '10em', display: 'inline-block'}} className="pl-4 text-gray-500">
                                                 <ReactTimeAgo date={video.lm} timeStyle="round-minute"/>
                                             </span>
                                             <span className="pl-4 text-gray-300">
                                                 {video.lm}
                                             </span>
                                         </p>
                                     </div>
                                 </li>);})}
                 </ul>
             </>
            }
        </>);
};

export default VideoList;
