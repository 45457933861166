import Config from './config.js';
import { OAuthPopup, useOAuth2 } from "@tasoskakour/react-use-oauth2";
import { Fragment, useState, useEffect, useCallback } from 'react';
import {jwtDecode} from "jwt-decode";

const UseAuth = () => {
    console.log("OAuth...", Config.authorizeUrl);
    const { data, loading, error, getAuth, logout } = useOAuth2({
        authorizeUrl: Config.authorizeUrl,
        clientId: Config.cognitoClientId,
        redirectUri: `${document.location.origin}/`,
        scope: "openid",
        responseType: "token",
        onSuccess: (payload) => console.log("Login Success", payload),
        onError: (error_) => console.log("Login Error", error_)
    });

    const token = data?.access_token;

    const [ userInfo, setUserInfo ] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(token) {
                    const response = await fetch(Config.userInfoUrl, {headers: {Authorization: 'Bearer ' + token},
                                                                      credentials: "include" } );
                    if(response.status === 200) {
                        const b = await response.json();
                        console.log("Userinfo", b);
                        setUserInfo(b);
                    }
                }
            } catch (err) {
                console.log("Error fetching userinfo", err);
            }
        };
        fetchData();
    }, [token]);

    if(error || loading) {
        return {loading, error, getAuth, logout};
    }

    const expired = data?.access_token && jwtDecode(data?.access_token).exp < new Date().getTime()/1000;
    const loggedIn = Boolean(data?.access_token && !expired); // or whatever...

    const d = {loggedIn, expired, token: data?.access_token, loading, error, getAuth, logout, userInfo };
    console.log("OAuth data", d);
    return d;
};

export default UseAuth;
