import './App.css';
import {  OAuthPopup, useOAuth2 } from "@tasoskakour/react-use-oauth2";
import { createHashRouter, BrowserRouter, Routes, Route, NavLink, Link} from "react-router-dom";
import { Fragment, useState, useEffect, useCallback } from 'react';
import { Disclosure, Dialog, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon, UserIcon } from '@heroicons/react/24/outline';
import VideoList from './VideoList.js';
import StationList from './StationList.js';
import Config from './config.js';
import DebugDialog from './debug.js';
import UseAuth from "./Auth.js";

const user = {
  name: '',
    email: '',
    imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

const navigation = [
  { name: 'Videos', id: 'videos', title: 'Videoarchiv', href: '/'},
  { name: 'Stationen', id: 'stations', title: 'Stationen verwalten', href: '/stations'},
];

const userNavigation =  [
  // { name: 'Settings', href: '#' },
  { name: 'Debug', id: "dbg", href: '#' },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const Home = ({token, logout, userInfo, station, setStation, getAuth }) => {
  const [isDbgOpen, setDbgState] = useState(false);
  const dbgClose = () => setDbgState(false);
  const dbgOpen = () => setDbgState(true);
  const [page, setPage] = useState(0);
  const h = (id) => {
    if (id === "dbg") { dbgOpen();  }
  };

  const stationName = userInfo?.stations.find(s => s.id === station)?.displayname;

  const Header = ({title}) =>
    <header className="bg-white shadow">
      <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
       <h1 className="text-3xl font-bold tracking-tight text-gray-900">{title}</h1>
      </div>
    </header>;


  return (
    <>
      <BrowserRouter>
        <div className="min-h-full">
          <Navigation />
          <Header title={navigation[page].title}/>

          <main>

            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
              <Routes>
                <Route path="/"         element={<VideoList token={token} station={station} stationName={stationName} />} />
          <Route path="/stations" element={<StationList station={station} userInfo={userInfo} setStation={setStation}/> } />
              </Routes>
              <DebugDialog isDbgOpen={isDbgOpen} dbgClose={dbgClose} token={token}/>
            </div>
          </main>
        </div>
      </BrowserRouter>
    </>
  );

  function Navigation() {
    return <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="h-8 w-8"
                    src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=500"
                    alt="Racecam" />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item,idx) => (
                        <>
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className={({isActive}) => classNames(
                                isActive
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={({isActive}) => (isActive ? 'page' : undefined)}
                          >
                            {item.name}
                          </NavLink>
                        </>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
              {/*
                 <button
                 type="button"
                 className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              */}

       {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
              <div>
              <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-6 w-6 text-gray-400 hover:text-white" />
              </Menu.Button>
              </div>
              <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg
                                             ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                        <>
                          <div className="ml-3">
                            <div className="text-base font-medium leading-none text-black">{userInfo?.name}</div>
                            <div className="text-sm font-medium leading-none text-gray-400">{userInfo?.email}</div>
                          </div>
                          </>
                          <>
                          <div>
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (

                                <a
                                  href={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                  onClick={() => h(item.id)}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          </div>
                        </>
                        <Menu.Item key="signout">
                          <button onClick={logout} className="block px-4 py-2 text-sm text-gray-700">Sign out</button>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item, idx) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                  onClick={() => { setPage(idx); }}

                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                    <UserIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                </div>
                  <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{userInfo?.name}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{userInfo?.email}</div>
                  </div>
                  {/* <button type="button" */}
                  {/*         className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" */}
                  {/* > */}
                  {/*     <span className="sr-only">View notifications</span> */}
                  {/*     <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
                  {/* </button> */}
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                          <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                          >
                              {item.name}
                          </Disclosure.Button>
                      ))}
                  </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>;
  }
};

const Main = () => {
    const [ station, setStation_ ] = useState(null);
    const setStation = (s) => {
        console.log("Setting station to ", s);
        if(station != s) {
            setStation_(s);
            localStorage.setItem('station', s);
        }
    };

    useEffect(() => {
        if(station === null) {
            const s = localStorage.getItem('station');
            console.log("Loading selected station:", s);
            if (s) setStation(s);
        }
    });

    if (document.location.hash.match(/id_token=/)) {
        console.log("HASH, document.location.hash");
        return ( <OAuthPopup />);
    } else {
        let {loggedIn, expired, token, loading, error, getAuth, logout, userInfo } = UseAuth();



        if(loggedIn) {
            if(userInfo) {
                console.log("Checking the station...", station, userInfo?.stations?.length);
                if(station === null && userInfo?.stations?.length > 0) {
                    console.log("Setting the station!!!");
                    setStation(userInfo.stations[0].id);
                }
                return (<Home token={token} logout={logout} userInfo={userInfo} station={station} setStation={setStation} getAuth={getAuth} />);
            } else {
                return (<div>Loading...</div>);
            }
        } else {
            return (
                <>
                  {expired && <div>Sitzung abgelaufen. Bitte erneut anmelden!</div>}
                  {error && <div>Fehler bei der Anmeldung: {error}</div>}
                  <button style={{ margin: "24px" }} className="text-white bg-blue-500 p-2 rounded" type="button" onClick={() => getAuth()}>
                    Login
                  </button>
                </>
            );
        }
    }
};


const App = () => {
    return (<Main />);
};

// <Route element={<OAuthPopup />} path="/callback" />

export default App;
