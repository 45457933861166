console.log("Process.env", process.env);
console.log("Init Config...");
const base = process.env.REACT_APP_API_BASE;
const Config = {
    userInfoUrl:      base + "/user",
    listVideosUrls:    base + "/videos",
    authorizeUrl:      process.env.REACT_APP_AUTHORIZER_URL,
    cognitoClientId:   process.env.REACT_APP_COGNITO_CLIENT_ID,
    videoPortalUrl:    process.env.REACT_APP_VIDEO_PORTAL_URL
};

console.log("Config is", Config);
export default Config;
